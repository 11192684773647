import React from "react";
import { Container } from "@chakra-ui/react";
import Header from "./header";
import { Script } from "gatsby";
import { MDXProvider } from "@mdx-js/react";
import EmailPopup from "./popup/email-popup";
import { Footer } from "./Footer";

const Layout = ({ children }) => {
  return (
    <>
      <Script
        src="https://js.sparkloop.app/team_441d586da752.js"
        async
        data-sparkloop="true"
      />
      <Script
        src="https://beamanalytics.b-cdn.net/beam.min.js"
        data-token="9ea5cf2c-e873-4c7c-95b3-763d2a6440d4"
        async
      />

      <Header />
      <Container maxW="4xl">
        <Container maxW="4xl" mt="10">
          <MDXProvider>{children}</MDXProvider>
        </Container>
        <EmailPopup />
      </Container>
      <Footer />
    </>
  );
};

export default Layout;
