import { Text } from "@chakra-ui/react";
import React from "react";

const Highlight = ({ children }) => {
	return (
		<Text
			bgGradient="linear(to-l, #7928CA, #FF0080)"
			bgClip="text"
			as="span"
			fontWeight="extrabold"
		>
			{children}
		</Text>
	);
};

export default Highlight;
