import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Heading,
  List,
  ListIcon,
  ListItem,
  Center,
} from "@chakra-ui/react";
import { BellIcon, CheckCircleIcon, EmailIcon } from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";
import EmailSignup from "../email-signup";
import useWindowWidth from "../useWindowWidth";
import Highlight from "../highlight";

const EmailPopup = () => {
  const width = useWindowWidth();
  const [isDismissed, setIsDismissed] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const closeModal = () => {
    setIsDismissed(true);
    sessionStorage.setItem("emailModalClosed", "true");
    onClose();
  };

  useEffect(() => {
    const handleMouseOut = (e) => {
      if (e.toElement === null && e.relatedTarget === null && !isDismissed) {
        onOpen();
      }
    };

    const emailModalClosed = sessionStorage.getItem("emailModalClosed");

    if (width >= 1024 && isDismissed !== true && emailModalClosed !== "true") {
      setTimeout(() => {
        document.addEventListener("mouseout", handleMouseOut);
      }, 3000);
    } else {
      setTimeout(() => {
        if (
          typeof window !== "undefined" &&
          !isDismissed &&
          emailModalClosed !== "true"
        ) {
          onOpen();
        }
      }, 60000);
    }

    return () => {
      document.removeEventListener("mouseout", handleMouseOut);
    };
  }, [isDismissed, onOpen, width]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={closeModal} size="lg" isCentered={true}>
        <ModalOverlay />
        <ModalContent pb={4}>
          <ModalHeader>
            <Heading
              mt="7"
              mx="10"
              as="h2"
              size="md"
              fontWeight={"extrabold"}
              textAlign="center"
            >
              Join <Highlight>1,659+ </Highlight>
              front end devs getting remote jobs delivered every Friday!
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <List spacing={3}>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="green.400" />
                  Fully remote front end jobs
                </ListItem>
                <ListItem>
                  <ListIcon as={BellIcon} color="yellow.400" />
                  New jobs every week
                </ListItem>
                <ListItem>
                  <ListIcon as={EmailIcon} color="gray.500" />
                  0.2% unsubscribe rate
                </ListItem>
              </List>
            </Center>
          </ModalBody>

          <ModalFooter>
            <EmailSignup size="md" />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EmailPopup;
