import React from "react";
import { Link as GatsbyLink, navigate } from "gatsby";
import {
	Flex,
	Spacer,
	Link,
	Button,
	useBreakpointValue,
	Box,
	ButtonGroup,
	Container,
	HStack,
	useColorModeValue,
	useDisclosure,
	Drawer,
	DrawerContent,
	DrawerCloseButton,
	DrawerHeader,
	DrawerBody,
	DrawerFooter,
	DrawerOverlay,
	VStack,
	List,
	ListItem,
	ListIcon,
	Divider,
	Text,
} from "@chakra-ui/react";
import { BsNewspaper, BsClipboard2CheckFill } from "react-icons/bs";
import { FaMoneyBill, FaClipboardCheck } from "react-icons/fa";
import { Logo } from "./Logo";
import Highlight from "./highlight";
import { EmailIcon, HamburgerIcon } from "@chakra-ui/icons";

function Header() {
	const isDesktop = useBreakpointValue({ base: false, xl: true });
	const { isOpen, onOpen, onClose } = useDisclosure();
	const btnRef = React.useRef();

	const linkTo = (link) => {
		onClose();
		navigate(link);
	};

	return (
		<>
			<Box as="section" pb={{ base: "6" }}>
				<Box
					as="nav"
					bg="bg-surface"
					boxShadow={useColorModeValue("sm", "sm-dark")}
				>
					<Container width="100%" maxW="5xl" py={{ base: "4", lg: "5" }}>
						<HStack spacing="2" justify="space-between">
							<HStack justify="flex-start">
								<Logo />
								<Link as={GatsbyLink} to="/" fontWeight={"bold"}>
									<Highlight>Front End Remote Jobs</Highlight>
								</Link>
							</HStack>
							<Spacer />
							{isDesktop ? (
								<Flex justify="flex-end" flex="1">
									<ButtonGroup spacing="8">
										<Button
											fontWeight={"bold"}
											as={GatsbyLink}
											to="/newsletter/"
											variant="link"
										>
											Newsletter
										</Button>
										<Button
											fontWeight={"bold"}
											as={GatsbyLink}
											to="/articles/"
											variant="link"
										>
											Blog
										</Button>
										<Button
											fontWeight={"bold"}
											as={GatsbyLink}
											to="/salary-guide/"
											variant="link"
										>
											Salary Guide [2023]
										</Button>
										<Button
											fontWeight={"bold"}
											as={GatsbyLink}
											to="/developers/"
											variant="link"
										>
											Reverse Job Board
										</Button>
										<Button
											fontWeight={"bold"}
											as={GatsbyLink}
											to="/hire"
											colorScheme={"teal"}
										>
											Hire
										</Button>
									</ButtonGroup>
								</Flex>
							) : (
								<>
									<Button
										ref={btnRef}
										variant="ghost"
										onClick={onOpen}
										size="sm"
										rightIcon={<HamburgerIcon />}
									>
										Menu
									</Button>
									<Drawer
										isOpen={isOpen}
										placement='right'
										onClose={onClose}
										finalFocusRef={btnRef}
									>
										<DrawerOverlay />
										<DrawerContent>
											<DrawerCloseButton />

											<DrawerHeader>
												<VStack>
													<Logo />
													<Button
														variant={"ghost"}
														fontWeight={"bold"}
														onClick={() => linkTo("/")}
													>
														<Highlight>Front End Remote Jobs</Highlight>
													</Button>
												</VStack>
											</DrawerHeader>

											<DrawerBody>
												<List alignContent={"center"} spacing="4">
													<ListItem>
														<ListIcon as={EmailIcon} />
														<Button
															variant={"ghost"}
															onClick={() => linkTo("/newsletter/")}
														>
															Newsletter
														</Button>
													</ListItem>
													<ListItem>
														<ListIcon as={BsNewspaper} />
														<Button
															variant={"ghost"}
															onClick={() => linkTo("/articles/")}
														>
															Blog
														</Button>
													</ListItem>
													<ListItem>
														<ListIcon as={FaMoneyBill} />
														<Button
															variant={"ghost"}
															onClick={() => linkTo("/salary-guide/")}
														>
															Salary Guide [2023]
														</Button>
													</ListItem>
													<ListItem>
														<ListIcon as={FaClipboardCheck} />
														<Button
															variant={"ghost"}
															onClick={() => linkTo("/developers/")}
														>
															Reverse Job Board
														</Button>
													</ListItem>
												</List>
												<Divider my="5" />
												<Flex>
													<Button
														fontWeight={"bold"}
														onClick={() => linkTo("/hire/")}
														colorScheme={"teal"}
														flex={1}
													>
														Hire
													</Button>
												</Flex>
											</DrawerBody>

											<DrawerFooter>
												<Text color="muted">
													Helping <Highlight>front end developers</Highlight>{" "}
													find remote jobs since 2018.
												</Text>
											</DrawerFooter>
										</DrawerContent>
									</Drawer>
								</>
							)}
						</HStack>
					</Container>
				</Box>
			</Box>
		</>
	);
}

export default Header;
