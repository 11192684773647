import kebabCase from "lodash/kebabCase";

export const regionSlug = (value) =>
	`/remote-front-end-developer-jobs-${kebabCase(value)}/`;

export const techSlug = (value) =>
	`/remote-${kebabCase(value)}-developer-jobs/`;
export const levelSlug = (value) =>
	`/${kebabCase(value)}-remote-front-end-developer-jobs/`;

export const summarySlug = (value) =>
	`/articles/${kebabCase(value)}-front-end-developer-hiring-trends/`;

export const hireSlug = (value) =>
	`/hire-remote-${kebabCase(value)}-developers/`;

export const salarySlug = (value) =>
	`/salary-guide/remote-${kebabCase(value)}-developer-salary/`;
