import { useStaticQuery, graphql } from "gatsby";

export const useTagPages = () => {
	const { technologyTags, seniorityTags, regionTags } = useStaticQuery(
		graphql`
      query Tags {

			technologyTags: allMdx {
				group(field:{ frontmatter: {technology_tags:SELECT}}) {
					fieldValue
					nodes {
						id
					}
				}
			}
			seniorityTags: allMdx {
				group(field:{ frontmatter: {seniority_tags:SELECT}}) {
					fieldValue
				}
			}
			regionTags: allMdx {
				group(field: {frontmatter: {region:SELECT}}) {
					fieldValue
				}
			}
    }

    `,
	);
	return {
		technologyTags,
		seniorityTags,
		regionTags,
	};
};
