import { extendTheme } from "@chakra-ui/react";
import { MultiSelectTheme } from "chakra-multiselect";

const newTheme = {
	styles: {
		global: {
			".mdx-prose": {
				h2: {
					fontSize: "3xl",
					mb: "8",
					mt: "16",
					fontWeight: "extrabold",
				},
				h3: {
					fontSize: "xl",
					mb: "6",
					mt: "12",
					// fontWeight: "extrabold",
				},
				p: {
					fontSize: '18',
					lineHeight: '1.8',
					mb: "8",
				},
				ul: {
					ml: "8",
					mb: "6",
				},
				a: {
					color: "cyan.800",
					fontWeight: "bold",
				},
				th: {
					px: "4",
					py: "1",
				},
				td: {
					px: "4",
					py: "1",
				},
				blockquote: {
					px: "8",
					py: "6",
					my: "7",
					marginLeft: 0,
					fontStyle: "italic",
					backgroundColor: "gray.100",
					borderLeft: "5px solid darkgray",
					p: {
						mb: 0,
					},
				},
				"blockquote + blockquote": {
					marginTop: -7,
					pt: -5,
				},
			},
		},
	},
	components: {
		MultiSelect: MultiSelectTheme,
	},
};

const theme = extendTheme(newTheme);

export default theme;
