import * as React from "react";

import {
	Box,
	Button,
	ButtonGroup,
	Container,
	Divider,
	HStack,
	IconButton,
	Link,
	Stack,
	Text,
} from "@chakra-ui/react";
import { FaFacebook, FaLinkedin, FaTwitter } from "react-icons/fa";
import { levelSlug, regionSlug, techSlug } from "../utils/slugs";

import EmailSignup from "./email-signup";
import { Link as GatsbyLink } from "gatsby";
import Highlight from "./highlight";
import { Logo } from "./Logo";
import { useTagPages } from "./useTagPages";

export const Footer = () => {
	const { technologyTags, regionTags, seniorityTags } = useTagPages();

	return (
		<Container as="footer" role="contentinfo" maxW={"5xl"}>
			<Stack
				spacing="8"
				direction={{ base: "column", lg: "row" }}
				justify="space-between"
				pt={{ base: "12", md: "16", lg: "24" }}
				pb={{ base: "8" }}
			>
				<Stack spacing={{ base: "2" }} align="start">
					<Box mb="10">
						<HStack>
							<Logo />
							<Text>
								<Highlight>Front End Remote Jobs</Highlight>
							</Text>
						</HStack>
						<Text color="muted" fontSize="sm">
							Helping front end developers find remote jobs since 2018.
						</Text>
					</Box>
					<Box pb="10">
						<Stack
							direction={{ base: "column-reverse", md: "column", lg: "row" }}
							spacing={{ base: "12", md: "8" }}
						>
							<Stack spacing="4">
								<Text fontSize="sm" fontWeight="semibold" color="subtle">
									Get weekly jobs every Friday!
								</Text>
								<Stack spacing="4">
									<EmailSignup size="sm" />
								</Stack>
							</Stack>
						</Stack>
					</Box>
					<Box pb={10}>
						<Stack spacing="4" minW="36" flex="1">
							<Text fontSize="sm" fontWeight="semibold" color="subtle">
								Resources
							</Text>
							<Stack spacing="3" shouldWrapChildren>
								<Button variant="link" as={GatsbyLink} to="/salary-guide/">
									Salary Guide
								</Button>
								<Button variant="link" as={GatsbyLink} to="/articles/">
									Articles
								</Button>
								<Button
									variant="link"
									as={GatsbyLink}
									to="/ai-cover-letter-for-front-end-developers/"
								>
									AI Cover Letter
								</Button>
							</Stack>
						</Stack>
					</Box>
					<Box pb={10}>
						<Stack direction="row" spacing="8">
							<Stack spacing="4" minW="36" flex="1">
								<Text fontSize="sm" fontWeight="semibold" color="subtle">
									Get Connected
								</Text>
								<Stack spacing="3" shouldWrapChildren>
									<Button variant="link" as={GatsbyLink} to="/newsletter/">
										Newsletter
									</Button>
									<Button variant="link" as={GatsbyLink} to="/hire/">
										Hire Remote Devs
									</Button>
									<Button variant="link" as={GatsbyLink} to="/">
										View all Jobs
									</Button>
								</Stack>
							</Stack>
						</Stack>
					</Box>
					<Box>
						<Stack direction="row" spacing="8">
							<Stack spacing="4" minW="36" flex="1">
								<Text fontSize="sm" fontWeight="semibold" color="subtle">
									Other Sites
								</Text>
								<Stack spacing="3" shouldWrapChildren>
									<Button as={Link} href="https://colorbliss.com" variant="link">
										ColorBliss - Coloring Page Generator
									</Button>
									<Button as={Link} href="https://hotelsforkids.net" variant="link">
										Hotels for Kids
									</Button>
									<Button as={Link} href="https://calculating.world/five-number-summary-calculator" variant="link">
										Five Number Summary Calculator
									</Button>
									<Button as={Link} href="https://calculating.world/year-percentage" variant="link">
										What percentage of {new Date().getFullYear()} is over?
									</Button>
								</Stack>
							</Stack>
						</Stack>
					</Box>
				</Stack>
				<Stack spacing="3" shouldWrapChildren>
					<Text fontSize="sm" fontWeight="semibold" color="subtle">
						By Technology
					</Text>
					{technologyTags.group.map((node) => {
						if (node.nodes.length < 3) return null;

						return (
							<Button
								variant="link"
								as={GatsbyLink}
								to={techSlug(node.fieldValue)}
								size={"xs"}
								key={node.fieldValue}
							>
								Remote {node.fieldValue} developer jobs
							</Button>
						);
					})}
				</Stack>
				<Stack spacing="3" shouldWrapChildren></Stack>
				<Stack spacing="3" shouldWrapChildren>
					<Text fontSize="sm" fontWeight="semibold" color="subtle">
						By Experience
					</Text>
					{seniorityTags.group.map((node) => {
						return (
							<Button
								variant="link"
								as={GatsbyLink}
								to={levelSlug(node.fieldValue)}
								size={"xs"}
								key={node.fieldValue}
							>
								{node.fieldValue} remote front end developer jobs
							</Button>
						);
					})}
					<Text fontSize="sm" fontWeight="semibold" color="subtle">
						By Location
					</Text>
					{regionTags.group.map((node) => {
						return (
							<Button
								variant="link"
								as={GatsbyLink}
								to={regionSlug(node.fieldValue)}
								size={"xs"}
								key={node.fieldValue}
							>
								Remote front end developer jobs in {node.fieldValue}
							</Button>
						);
					})}
				</Stack>
			</Stack>

			<Divider />

			<Stack
				pt="8"
				pb="12"
				justify="space-between"
				direction={{ base: "column-reverse", md: "row" }}
				align="center"
			>
				<Text fontSize="sm" color="subtle">
					&copy; {new Date().getFullYear()} Front End Remote Jobs. All rights
					reserved.
				</Text>
				<ButtonGroup variant="ghost">
					<IconButton
						as="a"
						href="https://www.linkedin.com/company/front-end-remote-jobs"
						aria-label="LinkedIn"
						icon={<FaLinkedin fontSize="1.25rem" />}
					/>
					<IconButton
						as="a"
						href="https://www.facebook.com/people/Front-End-Remote-Jobs/100089328608120/"
						aria-label="Facebook"
						icon={<FaFacebook fontSize="1.25rem" />}
					/>
					<IconButton
						as="a"
						href="https://twitter.com/remotefrontend"
						aria-label="Twitter"
						icon={<FaTwitter fontSize="1.25rem" />}
					/>
				</ButtonGroup>
			</Stack>
		</Container>
	);
};
