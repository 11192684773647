import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

type Props = {};

export const Logo = (props: Props) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      image: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 60
            height: 60
            layout: FIXED
            placeholder: BLURRED
          )
        }
      }
    }
  `);

  return (
    <GatsbyImage
      style={{ flexShrink: 0 }}
      image={data.image.childImageSharp.gatsbyImageData}
      alt="Front end remote jobs icon is a rainbow colored circle with a lightning bolt through it."
    />
  );
};
