import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import Layout from "./layout";
import theme from "../theme";

export const wrapPageElement = ({ element }) => {
	return (
		<ChakraProvider theme={theme} resetCSS={true}>
			<Layout>{element}</Layout>
		</ChakraProvider>
	);
};
