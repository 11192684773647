/* eslint no-undef: 0 */

import * as React from "react";
import {
  InputGroup,
  Input,
  Button,
  FormControl,
  InputLeftElement,
  FormErrorMessage,
  FormHelperText,
  Stack,
} from "@chakra-ui/react";
import { EmailIcon } from "@chakra-ui/icons";

function EmailSignup({ size = "lg" }) {
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const onChange = (event) => {
    setEmail(event.target.value);

    setSuccess(false);
    setError(false);
  };

  const submit = () => {
    setLoading(true);
    fetch("/api/subscribe", {
      method: "POST",
      body: JSON.stringify({
        email,
      }),
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => {
        console.log({ res });
        setLoading(false);
        return res.json();
      })
      .then((body) => {
        console.log(body);
        if (body.status === 201 || body.status === 200) {
          if (SL) {
            SL.trackSubscriber(email);
          }
          setError(false);
          setSuccess(true);
        } else {
          setLoading(false);
          setError(body.statusText);
          setSuccess(false);
        }
      });
  };

  return (
    <FormControl maxW={"md"} isInvalid={error}>
      <Stack spacing="2" direction={{ base: "column", sm: "row" }}>
        <InputGroup size={size} flex="1">
          <InputLeftElement
            pointerEvents="none"
            children={<EmailIcon color="gray.300" />}
          />
          <Input
            type="email"
            placeholder="Get weekly jobs!"
            value={email}
            onChange={onChange}
            required={true}
          />
        </InputGroup>

        <Button
          isLoading={loading}
          ml="2"
          flexShrink={0}
          size={size}
          onClick={submit}
          colorScheme="teal"
        >
          Submit
        </Button>
      </Stack>
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
      {success && (
        <FormHelperText>
          Success! Please check your email to verify your subscription!
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default EmailSignup;
